<template>
    <div>
        <div class="card">
            <div class="card-body">
                <ValidationObserver ref="validation-observer">
                    <div class="row card__box-shadow" style="margin:2rem 0;padding:1.5rem 1rem">
                        <b-col cols="6">
                            <ValidationProvider :name="$t('roles_permission.name')" rules="required"
                                                v-slot="{errors}">
                                <b-form-group
                                    :label="$t('roles_permission.name')"
                                    label-for="role_name"

                                >
                                    <b-form-input
                                        v-model="name"
                                        id="role_name"
                                        size="lg"
                                        :placeholder="$t('roles_permission.name')"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                            <ValidationProvider :name="$t('roles_permission.is_admin')" rules="required"
                                                v-slot="{errors}">
                                <b-form-group
                                    :label="$t('roles_permission.is_admin')"
                                    label-for="is_admin"

                                >
                                    <b-form-checkbox
                                        switch
                                        v-model="is_admin"
                                        id="is_admin"
                                        :label="$t('roles_permission.is_admin')"
                                        :placeholder="$t('roles_permission.is_admin')"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>
                    </div>
                </ValidationObserver>
                <b-tabs content-class="mt-3">
                    <b-tab
                        v-for="({title,active,rows,id},pmIndex) in permissionTabs"
                        :key="id"
                        :title="$t(title)"
                        :active="active"
                    >
                        <table class="table">
                            <tbody>
                            <tr v-for="({
                    label,width,
                    refer,checkboxSwitch,
                    checkboxActive,checkboxSize,inputActive,
                    inputClass,inputPlaceholder,inputType},index) in rows" :key="index+label+id"
                            >
                                <td :width="width">
                                    {{ $t(label) }}
                                </td>
                                <td v-if="checkboxActive">
                                    <b-form-checkbox
                                        :switch="checkboxSwitch"
                                        :size="checkboxSize"
                                        v-model="permissionTabs[pmIndex]['rows'][index].vBind"
                                        @input="activeAllTabPermission(refer,pmIndex,index,$event)"
                                    ></b-form-checkbox>
                                </td>
                                <td v-if="inputActive">
                                    <input
                                        :type="inputType"
                                        :class="inputClass"
                                        :placeholder="$t(inputPlaceholder)"
                                        v-model="name[refer]"
                                    >
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="card-footer d-flex">
                <button class="btn btn-primary" @click="createRole">
                    <i class="fas fa-save"></i> {{ $t("save") }}
                </button>

                <button class="btn btn-default" @click="$router.go(-1)">
                    {{ $t("cancel") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/api'
import {v4 as uuid} from 'uuid';
import {
    BFormCheckbox,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BCol
} from 'bootstrap-vue'
import ToastNotification from "@core/components/toastification/ToastNotification";

export default {
    name: "Id",
    components: {
        BFormCheckbox,
        BTabs,
        BTab,
        BFormGroup,
        BFormInput,
        BCol
    },
    data() {
        const roleId = this.$route.params.id || null

        const crudPermission = {
            view: false,
            create: false,
            update: false,
            delete: false,
            edit: 1,
            show: true,
            index: true,
            store: true,
            destroy: false
        }

        const name = ''

        const form = {
            users: {
                ...crudPermission,
            },
            roles: {
                ...crudPermission
            },

        }

        const row = {
            width: '50%',
            inputActive: false,
            inputType: 'text',
            inputPlaceholder: '',
            inputClass: 'form-control',
            checkboxActive: true,
            checkboxSize: 'lg',
            checkboxSwitch: true,
            vBind: false
        }


        return {
            roleId,
            form,
            name,
            is_admin: false,
            role: {},
            permissionTabs: [
                {
                    id: uuid(),
                    title: 'roles_permission.titles.roles',
                    active: false,
                    parent: 'form',
                    rows: [
                        {
                            ...row,
                            label: 'roles_permission.activate_all',
                            refer: 'all',
                            parent: 'roles',
                        },
                        {
                            ...row,
                            label: 'roles_permission.roles.watch_roles',
                            refer: 'view',
                            parent: 'roles',
                        },

                        {
                            ...row,
                            label: 'roles_permission.roles.watch_roles',
                            refer: 'index',
                            parent: 'roles',
                        },

                        {
                            ...row,
                            label: 'roles_permission.roles.watch_roles',
                            refer: 'show',
                            parent: 'roles',
                        },

                        {
                            ...row,
                            label: 'roles_permission.roles.create_roles',
                            refer: 'create',
                            parent: 'roles',
                        },

                        {
                            ...row,
                            label: 'roles_permission.roles.create_roles',
                            refer: 'store',
                            parent: 'roles',
                        },

                        {
                            ...row,
                            label: 'roles_permission.roles.edit_roles',
                            refer: 'update',
                            parent: 'roles',
                        },

                        {
                            ...row,
                            label: 'roles_permission.roles.edit_roles',
                            refer: 'edit',
                            parent: 'roles',
                        },

                        {
                            ...row,
                            label: 'roles_permission.roles.delete_roles',
                            refer: 'delete',
                            parent: 'roles',
                        },
                        {
                            ...row,
                            label: 'roles_permission.roles.delete_roles',
                            refer: 'destroy',
                            parent: 'roles',
                        }
                    ],
                },
                {
                    id: uuid(),
                    title: 'roles_permission.titles.users',
                    active: false,
                    parent: 'form',
                    rows: [
                        {
                            ...row,
                            label: 'roles_permission.activate_all',
                            refer: 'all',
                            parent: 'users',
                        },
                        {
                            ...row,
                            label: 'roles_permission.users.watch_users',
                            refer: 'view',
                            parent: 'users',
                        },

                        {
                            ...row,
                            label: 'roles_permission.users.watch_users',
                            refer: 'index',
                            parent: 'users',
                        },
                        {
                            ...row,
                            label: 'roles_permission.users.watch_users',
                            refer: 'show',
                            parent: 'users',
                        },

                        {
                            ...row,
                            label: 'roles_permission.users.create_users',
                            refer: 'create',
                            parent: 'users',
                        },

                        {
                            ...row,
                            label: 'roles_permission.users.create_users',
                            refer: 'store',
                            parent: 'users',
                        },


                        {
                            ...row,
                            label: 'roles_permission.users.edit_users',
                            refer: 'update',
                            parent: 'users',
                        },

                        {
                            ...row,
                            label: 'roles_permission.users.edit_users',
                            refer: 'edit',
                            parent: 'users',
                        },

                        {
                            ...row,
                            label: 'roles_permission.users.delete_users',
                            refer: 'delete',
                            parent: 'users',
                        },
                        {
                            ...row,
                            label: 'roles_permission.users.delete_users',
                            refer: 'destroy',
                            parent: 'users',
                        }
                    ],
                },
                // Category add
                {
                    id: uuid(),
                    title: 'roles_permission.titles.categories',
                    active: false,
                    parent: 'form',
                    rows: [
                        {
                            ...row,
                            label: 'roles_permission.activate_all',
                            refer: 'all',
                            parent: 'categories',
                        },
                        {
                            ...row,
                            label: 'roles_permission.categories.create_category',
                            refer: 'create',
                            parent: 'categories',
                        },

                        {
                            ...row,
                            label: 'roles_permission.categories.update_category',
                            refer: 'update',
                            parent: 'categories',
                        },
                        {
                            ...row,
                            label: 'roles_permission.categories.delete_category',
                            refer: 'delete',
                            parent: 'categories',
                        },
                    ],
                },
                {
                    id: uuid(),
                    title: 'roles_permission.titles.brands',
                    active: false,
                    parent: 'form',
                    rows: [
                        {
                            ...row,
                            label: 'roles_permission.activate_all',
                            refer: 'all',
                            parent: 'brands',
                        },
                        {
                            ...row,
                            label: 'roles_permission.brands.create_brand',
                            refer: 'create',
                            parent: 'brands',
                        },

                        {
                            ...row,
                            label: 'roles_permission.brands.update_brand',
                            refer: 'update',
                            parent: 'brands',
                        },
                        {
                            ...row,
                            label: 'roles_permission.brands.delete_brand',
                            refer: 'delete',
                            parent: 'brands',
                        },
                    ],
                },
                {
                    id: uuid(),
                    title: 'roles_permission.titles.products',
                    active: false,
                    parent: 'form',
                    rows: [
                        {
                            ...row,
                            label: 'roles_permission.activate_all',
                            refer: 'all',
                            parent: 'products',
                        },
                        {
                            ...row,
                            label: 'roles_permission.products.create_product',
                            refer: 'create',
                            parent: 'products',
                        },

                        {
                            ...row,
                            label: 'roles_permission.products.update_product',
                            refer: 'update',
                            parent: 'products',
                        },
                        {
                            ...row,
                            label: 'roles_permission.products.delete_product',
                            refer: 'delete',
                            parent: 'products',
                        },
                    ],
                },
            ],
            getLoading: false,
            permissions: {},
            pageType: 'create',
        }
    },
    async created() {
        const {id: roleId} = this.$route.params
        if (roleId) {
            this.pageType = 'update'
            await this.getRole()
            this.initPermissions()
        }

    },
    methods: {
        showToast(variant, text, icon) {
            this.$toast({
                component: ToastNotification,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            })
        },

        async getRole() {
            await api.role.getRole(this.roleId).then((res) => {
                this.name = res.data.data.name
                this.permissions = res.data.data.permissions
                this.is_admin = !!res.data.data.is_admin
            }).catch(() => {
                this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
        },
        async createRole() {
            const isValid = await this.$refs['validation-observer'].validate()
            if (isValid) {
                this.generateRole()
                let data = {
                    name: this.name,
                    permissions: this.form,
                    is_admin: this.is_admin
                }
                if (this.pageType === 'update') {
                    await api.role.updateRole(this.roleId, data).then(() => {
                        this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
                        this.$router.push({name: 'roles'})
                    }).catch(() => {
                        this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                    })
                } else {
                    this.getLoading = true
                    await api.role.createRole(data).then(() => {
                        this.showToast('success', 'Успешно создано!', 'CheckIcon')
                        this.$router.push({name: 'roles'})
                    }).catch(() => {
                        this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                    })
                }

            }
        },
        initPermissions() {
            this.form = {
                ...this.form,
                ...this.permissions
            }
            this.permissionTabs = this.permissionTabs.map(pmTab => {
                const rows = pmTab.rows.map(row => {
                    const pmTabParent = this[pmTab.parent][row.parent]
                    const hierarchyList = row.refer.split('.')
                    const [one, two, three] = hierarchyList

                    switch (hierarchyList.length) {
                        case 1 : {
                            row.vBind = pmTabParent[one] ?? false
                            break
                        }
                        case 2 : {
                            const hasChild = pmTabParent.hasOwnProperty(one)
                            if (hasChild) {
                                row.vBind = pmTabParent[one][two] ?? false
                            } else {
                                row.vBind = false
                            }
                            break

                        }
                        case 3 : {
                            const hasOne = pmTabParent.hasOwnProperty(one)
                            if (hasOne) {
                                row.vBind = pmTabParent[one][two][three]
                            } else {
                                row.vBind = false
                            }
                            break
                        }
                    }
                    return row
                })

                const isAllActive = rows.every(row => {
                    const overlookList = ['all']
                    if (overlookList.includes(row.refer)) {
                        return true
                    }
                    return row.vBind
                })
                if (isAllActive) {
                    const indexOfAllSwitch = rows.findIndex(row => row.refer === 'all')
                    if (indexOfAllSwitch !== -1) {
                        rows[indexOfAllSwitch].vBind = true
                    }
                }
                return {
                    ...pmTab,
                    rows
                }
            })
        },
        activeAllTabPermission(refer, pmIndex, index, value) {
            if (refer === 'all') {
                this.permissionTabs[pmIndex]['rows'] = this.permissionTabs[pmIndex]['rows'].map(row => {
                    return {
                        ...row,
                        vBind: value
                    }
                })
            }
        },
        generateRole() {
            this.permissionTabs.forEach(pmTab => {
                pmTab.rows.filter((row => {
                    const overlookList = ['all']
                    return !overlookList.includes(row.refer)
                })).forEach(row => {
                    const pmTabParent = this[pmTab.parent][row.parent]
                    const hierarchyList = row.refer.split('.')
                    const [one, two, three] = hierarchyList
                    switch (hierarchyList.length) {
                        case 1 : {
                            pmTabParent[one] = row.vBind
                            break
                        }
                        case 2 : {
                            const hasChild = pmTabParent.hasOwnProperty(one)
                            if (hasChild) {
                                row.vBind = pmTabParent[one][two] ?? false
                            } else {
                                row.vBind = false
                            }
                            break
                        }
                        case 3 : {
                            const hasOne = pmTabParent.hasOwnProperty(one)
                            if (hasOne) {
                                row.vBind = pmTabParent[one][two][three]
                            } else {
                                row.vBind = false
                            }
                            break
                        }
                    }
                })
            })
        },
    }
}
</script>

<style lang="scss" scoped>

.validation__red {
    color: red;
    font-size: 12px;
    display: block;
    //margin-bottom: 1rem;
}

</style>